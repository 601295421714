import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Hidden,
  Drawer,
  CssBaseline,
  AppBar,
  Badge,
  ListItemSecondaryAction,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  ArrowBackIos as ArrowBackIosIcon,
  ExitToApp as ExitToAppIcon,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useParams, useRouteMatch } from "react-router-dom";
import { useChat, useCategorias } from "../Hooks";
import LogoTempjob from "../../assets/logo-tempjob.png";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    "& .MuiTypography-h6": {
      color: "black",
      fontWeight: "bold",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  exitButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    transform: "rotate(180deg)",
  },
  toolbar: {
    ...theme.mixins.toolbar,
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    justifyContent: "space-between",
  },
  title: {
    textDecoration: "none",
    color: "white",
  },
  logoTempjob: {
    marginRight: theme.spacing(2),
    width: 200,
  },
}));

function BaseLayout({ window, children, showDrawer = true, title="" }) {
  const params = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const loginHash = localStorage.getItem("@chat-tempjob/loginHash");
  const { getCategories, getCategory } = useCategorias();
  const { chats } = useChat(loginHash, getCategories(loginHash?.split("."))?.map(categorias => categorias.id));
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const DrawerContent = ({ mobile }) => (
    <>
      {!mobile ? (
        <>
          <Toolbar />
          <div className={classes.drawerContainer}>
            <List>
              <ListItem button component={Link} to="/">
                <ListItemIcon>
                  <ArrowBackIosIcon />
                </ListItemIcon>
                <ListItemText primary="Chamados" />
              </ListItem>
              {chats.map((chat) => {
                const unreadMessages = chat?.messages?.filter(
                  (message) => message.user !== loginHash && message.read_at === null
                );
                return (
                  <ListItem
                    selected={params.key === chat.key}
                    button
                    component={Link}
                    to={`/chat/${chat.key}`}
                    key={chat.key}
                  >
                    <ListItemText
                      primary={`${chat.name} - ${getCategory(chat.categoria)?.name || ''}`}
                      primaryTypographyProps={{
                        noWrap: true,
                      }}
                      secondary={chat?.messages[0]?.message || ''}
                      secondaryTypographyProps={{
                        noWrap: true,
                        style: {
                          fontWeight: unreadMessages?.length > 0 ? "bold" : "normal",
                        },
                      }}
                    />
                    {unreadMessages?.length > 0 && (
                      <ListItemSecondaryAction>
                        <Badge color="secondary" badgeContent={unreadMessages?.length} />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                );
              })}
            </List>
          </div>
        </>
      ) : (
        <>
          <List>
            <ListItem button component={Link} to={"/"}>
              <ListItemIcon>
                <ArrowBackIosIcon />
              </ListItemIcon>
              <ListItemText primary="Chamados" />
            </ListItem>
            {chats.map((chat) => {
              const unreadMessages = chat?.messages?.filter(
                (message) => message.user !== loginHash && message.read_at === null
              );
              return (
                <ListItem
                  selected={params.key === chat.key}
                  button
                  component={Link}
                  to={`/chat/${chat.key}`}
                  key={chat.key}
                >
                  <ListItemText
                    primary={`${chat.name} - ${getCategory(chat.categoria)?.name || ''}`}
                    primaryTypographyProps={{
                      noWrap: true,
                    }}
                    secondary={chat?.messages[0]?.message || ''}
                    secondaryTypographyProps={{
                      noWrap: true,
                      style: {
                        fontWeight: unreadMessages?.length > 0 ? "bold" : "normal",
                      },
                    }}
                  />
                  {unreadMessages?.length > 0 && (
                    <ListItemSecondaryAction>
                      <Badge color="secondary" badgeContent={unreadMessages?.length} />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Toolbar>
            {showDrawer && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            )}
            <img src={LogoTempjob} className={classes.logoTempjob} alt="Logo Tempjob" />
            <Hidden xsDown implementation="css">
              <Typography variant="h6" noWrap>
                Tempjob
              </Typography>
            </Hidden>
          </Toolbar>
        </Toolbar>
      </AppBar>
      {showDrawer && (
        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <DrawerContent mobile />
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <DrawerContent />
            </Drawer>
          </Hidden>
        </nav>
      )}
      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>
    </div>
  );
}

export default BaseLayout;
