import { useState } from "react";
import md5 from "md5";

function useCategorias() {
  const allCategorias = [
    { id: "financeiro", name: "Financeiro" },
    { id: "suporte", name: "Suporte" },
    { id: "empresa", name: "Empresa" },
    { id: "candidato", name: "Candidato" },
    { id: "geral", name: "Geral" },
  ];

  const categoriasOrdered = allCategorias.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  const [categorias] = useState(categoriasOrdered);

  const getCategory = (id) => {
    const category = categorias.find((category) => md5(category.id) === id || category.id === id);
    return category ? category : null;
  }

  const getCategories = (ids) => {
    if(!ids || !ids?.length) return [];
    const categories = categorias.filter((categoria) => ids.includes(categoria.id) || ids.includes(md5(categoria.id)));
    return categories;
  }

  return {
    categorias,
    getCategory,
    getCategories,
  };
}

export default useCategorias;
