import React, { useState, useEffect } from "react";
import { useMessage, useCategorias } from '../../Hooks';
import BaseLayout from "../../Components/BaseLayout";
import MessageList from "../../Components/Chat/MessageList";
import { useParams } from "react-router";
import useChat from "../../Hooks/useChat";
import { Redirect } from "react-router";

function Chat() {
  const { key } = useParams();
  const loginHash = localStorage.getItem('@chat-tempjob/loginHash');
  const { getCategories } = useCategorias();
  const { messages } = useMessage(key);
  const { chats } = useChat(loginHash, getCategories(loginHash?.split("."))?.map(categorias => categorias.id));
  const [chat, setChat] = useState(null);

  if (!loginHash) {
    return (<Redirect to="/login" />);
  }

  useEffect(() => {
    const chatData = chats.filter((chat) => chat.key === key)[0];
    setChat(chatData);
    document.title = `Central de Chamados Tempjob - Atendimento ${chatData?.title}`;
  }, [key, chats]);

  return (
    <BaseLayout title={chat?.title}>
      <MessageList messages={messages} chat={chat}/>
    </BaseLayout>
  );
}

export default Chat;
