import { useEffect, useState } from "react";
import md5 from "md5";

function useIsCommomUser(userUuid) {
  const [isCommomUser, setIsCommomUser] = useState(false);

  useEffect(() => {
    const commomUserHash = md5("is_candidato");
    const userHash = userUuid?.split(".")[0];

    setIsCommomUser(commomUserHash === userHash);
  }, [userUuid]);

  return {
    isCommomUser,
  };
}

export default useIsCommomUser;
