import { useEffect, useState } from "react";
import { MessagesService } from "../Services";

function useMessages(chat) {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    MessagesService.observeMessages(chat, (querySnapshot) => {
      const messages = querySnapshot.docs.map((doc) => ({
        key: doc.id,
        ...doc.data(),
      }));
      setMessages(messages);
    });
  }, [chat]);

  return {
    messages,
  };
}

export default useMessages;
