import { db, firebase } from "../Firebase";

const ChatsService = {
  async getChat(userUuid, categoria) {
    if (!userUuid) {
      return;
    }
    const chatsDocs = await db.collection("chats")
      .where('userUuid', '==', userUuid)
      .where('categoria', '==', categoria)
      .get();
    const chats = [];
    chatsDocs.forEach((chat) => {
      chats.push({
        id: chat.id,
        ...chat.data()
      });
    });
    return chats[0];
  },

  observeChats(user, categories, callback) {
    if (categories?.length) {
      db.collection("chats").where("categoria", "in", categories).orderBy('last_message_at', 'desc').onSnapshot(callback);
    } else {
      db.collection("chats")
        .where("userUuid", "==", user)
        .orderBy("last_message_at", "desc")
        .onSnapshot(callback);
    }
  },

  async creatChat(data) {
    let chat = await this.getChat(data.userUuid, data.categoria);
    
    if (!chat) {
      chat = await db.collection("chats").add({
        ...data,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        last_message_at: null,
      });
    }

    return chat.id;
  },
};

export default ChatsService;
