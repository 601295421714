import { db, firebase } from "../Firebase";

const MessagesService = {
  async getMessages(chat) {
    const messagesDoc = await db
      .collection(`chats`)
      .doc(chat)
      .collection("messages")
      .orderBy("created_at", "desc")
      .get();
    const messages = [];
    messagesDoc.forEach((message) => {
      messages.push({
        id: message.id,
        ...message.data(),
      });
    });
    return messages;
  },

  observeMessages(chat, callback) {
    db.collection(`chats`).doc(chat).collection("messages").orderBy("created_at", "asc").onSnapshot(callback);
  },

  async sendMessage(message, chat, user) {
    db.collection(`chats`).doc(chat).update({
      last_message_at: firebase.firestore.FieldValue.serverTimestamp(),
    });

    db.collection(`chats`)
      .doc(chat)
      .collection("messages")
      .where("user", "!=", user)
      .get()
      .then((messages) => {
        messages.forEach((message) => {
          message.ref.update({
            read_at: firebase.firestore.FieldValue.serverTimestamp(),
          });
        });
      });

    db.collection(`chats`).doc(chat).collection("messages").add({
      message,
      user,
      read_at: null,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
    });
  },

  async readMessage(message, chat) {
    db.collection(`chats`).doc(chat).collection("messages").doc(message).update({
      read_at: firebase.firestore.FieldValue.serverTimestamp(),
    });
  },

  async markAllAsRead(chat, user) {
    db.collection(`chats`)
      .doc(chat)
      .collection("messages")
      .where("user", "!=", user)
      .get()
      .then((messages) => {
        messages.forEach((message) => {
          message.ref.update({
            read_at: firebase.firestore.FieldValue.serverTimestamp(),
          });
        });
      });
  },
};

export default MessagesService;
