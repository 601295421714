import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { ChatsService } from "../../Services";
import { useCategorias } from "../../Hooks";
import { useHistory, useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ModalCreateChat({ open, handleClose }) {
  const history = useHistory();
  const loginHash = localStorage.getItem('@chat-tempjob/loginHash');
  const classes = useStyles();
  const [name, setName] = useState("");
  const [categoria, setCategoria] = useState("");
  const { categorias } = useCategorias();

  const handleSubmitButton = useCallback(async (name, categoria) => {
    const chat = await ChatsService.creatChat({
      name,
      categoria,
      userUuid: loginHash,
    });

    if (chat) {
      handleClose();
      history.push(`/chat/${chat}`);
      window.location.reload();
    }

  }, []);

  return (
    <div>
      <Dialog disableEscapeKeyDown open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Canal de Atendimento Tempjob</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para iniciar uma conversa primeiro selecione seu categoria e digite seu nome
          </DialogContentText>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <InputLabel id="categoria">Categoria</InputLabel>
            <Select
              labelId="categoria"
              fullWidth
              value={categoria}
              onChange={(event) => setCategoria(event.target.value)}
              label="Categoria"
            >
              <MenuItem value="">
                <em>Selecione seu categoria</em>
              </MenuItem>
              {categorias.map((categoria) => (
                <MenuItem key={categoria.id} value={categoria.id}>
                  {categoria.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            onChange={(event) => setName(event.target.value)}
            autoFocus
            id="name"
            label="Nome"
            type="text"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => window.close()} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleSubmitButton(name, categoria)} color="primary">
            Iniciar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
