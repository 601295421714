import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#893bad",
      main: "#6b2e87",
      dark: "#532369",
      contrastText: "#fff",
    },
    secondary: {
      light: "#f1f1f1",
      main: "#eeeeee",
      dark: "#b2b2b2",
      contrastText: "#000",
    },
    background: {
      default: "#f1f1f1",
    }
  },
});

export default function Theme({children}){
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}
