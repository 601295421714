import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, Grid } from "@material-ui/core";
import { useRouteMatch } from 'react-router';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(1),
  },
  root: {
    padding: theme.spacing(1),
    position: "relative",
  },
  message: {
    margin: 0,
    padding: 0,
  },
  date: {
    margin: 0,
    marginTop: 4,
    padding: 0,
    fontSize: 12,
  },
  received: {
    backgroundColor: theme.palette.background.paper,
    "& span.arrow": {
      position: "absolute",
      top: 4,
      left: -7,
      background: theme.palette.background.paper,
      width: 16,
      height: 16,
      transform: "rotate(45deg)",
    },
    "& .dateAlign": {
      textAlign: "left",
    },
  },
  sended: {
    backgroundColor: "rgb(220, 220, 220)",
    "& span.arrow": {
      position: "absolute",
      top: 4,
      right: -7,
      background: "rgb(220, 220, 220)",
      width: 16,
      height: 16,
      transform: "rotate(45deg)",
    },
    "& .dateAlign": {
      textAlign: "right",
    },
  },
}));

function MessageBubble({ message = "", user = "", date = "" }) {
  const classes = useStyles();
  const loginHash = localStorage.getItem('@chat-tempjob/loginHash');
  
  const alignItems = () => {
    return user === loginHash ? 'flex-end' : 'flex-start';
  }

  const received = () => {
    return user !== loginHash ? classes.sended : classes.received;
  };

  return (
    <Grid
      direction="column"
      className={[classes.container]}
      container
      alignItems={alignItems()}
      justifyContent="flex-end"
    >
      <Grid item>
        <Paper elevation={0} className={[classes.root, received()]}>
          <span className="arrow" />
          <Typography className={classes.message} paragraph>
            {message}
          </Typography>
          <Typography className={[classes.date, 'dateAlign']} paragraph>
            {new Date(date * 1000).toLocaleString("pt-BR")}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default MessageBubble;