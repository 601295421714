import React, {useEffect, useRef} from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import MessageBubble from "./MessageBubble";
import MessageInput from "./MessageInput";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: `calc(100vh - ${theme.spacing(13)}px)`,
  },
  messages: {
    overflow: "scroll",
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(12),
    maxHeight: `calc(100vh - ${theme.spacing(13)}px)`,
  },
}));


function MessageList({ messages, hasDrawer = true, chat }) {
  const classes = useStyles();
  const messagesListRef = useRef(null);

  useEffect(() => {
    if (messagesListRef?.current) {
      const scroll = messagesListRef.current.scrollHeight - messagesListRef.current.clientHeight;
      messagesListRef.current.scrollTo(0, scroll);
    }
  }, [messages]);

  return (
    <div className={classes.root}>
      <Grid
        ref={messagesListRef}
        className={classes.messages}
        container
        spacing={0}
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        {messages.map((message, index) => (
          <MessageBubble key={index} user={message.user} message={message.message} date={message.created_at?.seconds} />
        ))}
      </Grid>
      <MessageInput hasDrawer={hasDrawer} chat={chat} />
    </div>
  );
}

export default MessageList;