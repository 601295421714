import firebase from "firebase";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAO70pHGWCmPmnzdgk6_4YnD54aY83eXs0",
  authDomain: "chat-tempjob-3779f.firebaseapp.com",
  projectId: "chat-tempjob-3779f",
  storageBucket: "chat-tempjob-3779f.appspot.com",
  messagingSenderId: "907845589008",
  appId: "1:907845589008:web:2a6610f3c2d379c44f1295"
};

firebase.initializeApp(firebaseConfig);

export { firebase };

export const db = firebase.firestore();
