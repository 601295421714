import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import { ChamadosList, ChamadosChat } from "../Screens/Chamados";
import Login from "../Screens/Login";

export default function Routes() {
  const loginHash = localStorage.getItem('@chat-tempjob/loginHash');
  return (
    <Router>
      <Switch>
        <Route path={["/login/:loginHash", "/login"]}>
          <Login />
        </Route>
        <Route path="/" exact>
          <ChamadosList /> 
        </Route>
        <Route path="/chat/:key">
          <ChamadosChat />
        </Route>
      </Switch>
    </Router>
  );
}
