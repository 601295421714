import React from "react";

import Route from './Routes';
import Theme from "./Theme";

function App() {
  return (
    <Theme>
      <Route />
    </Theme>
  );
}

export default App;
