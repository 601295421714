import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Fab } from "@material-ui/core";
import BaseLayout from "../../Components/BaseLayout";
import {useCategorias, useChat, useIsCommomUser} from "../../Hooks";
import CardChat from "./CardChat";
import ModalCreateChat from "./ModalCreateChat";
import AddIcon from '@material-ui/icons/Add';
import { Redirect } from "react-router";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  marginBottomFab: {
    marginBottom: theme.spacing(9),
  }
}));

function List() {
  const loginHash = localStorage.getItem('@chat-tempjob/loginHash');
  const { getCategories } = useCategorias();
  const { chats } = useChat(loginHash, getCategories(loginHash?.split("."))?.map(categorias => categorias.id));
  const {isCommomUser} = useIsCommomUser(loginHash);
  const [title] = useState("Painel de Atendimento");
  const [open, setOpen] = useState(false);

  if (!loginHash) {
    return (<Redirect to="/login" />);
  }

  const classes = useStyles();

  useEffect(() => { document.title = `Central de Chamados Tempjob - ${title}` }, [title]);
  
  return (
    <BaseLayout showDrawer={false} title={title}>
      {!isCommomUser && (
        <Typography paragraph>
          Olá, selecione um dos chamados abaixo para responder
        </Typography>
      )}
      <Grid container spacing={2} className={classes.marginBottomFab}>
        {chats.map((chat) => (
          <CardChat key={chat.key} chat={chat} />
        ))}
      </Grid>
      {isCommomUser && (
        <>
          <ModalCreateChat open={open} handleClose={() => setOpen(false)} />
          <Fab className={classes.fab} color="primary" aria-label="add" onClick={() => setOpen(true)}>
            <AddIcon />
          </Fab>
        </>
      )}
    </BaseLayout>
  );
}

export default List;
